import { features } from "../../utils/access.js";
import { useParams } from "react-router-dom";
import usePolicies from "../policies/usePolicies";

const useHasAccess = (module) => {
  const feature = features[module];
  const {clubId} = useParams();
  const policies = usePolicies(clubId);

  // Club Based Feature filter
  if (feature && policies) {
    const featureSupports = policies?.club?.featureSupports;
    const isAllowed = featureSupports?.find(
      (feat) => feat?.name == feature?.alias
    );

    if (!!isAllowed?.status) {
      return true;
    } else {
      return false;
    }
  }
};
export default useHasAccess;
