import {Route, Routes} from 'react-router-dom';
import PageForgotPassword
  from './pages/Onboarding/PageForgotPassword';
import PageLogin from './pages/Onboarding/PageLogin';
import PageResetPassword from './pages/Onboarding/PageResetPassword';
import AccessAuthenticated from './access/AccessAuthenticated';
import LayoutPublicPayments
  from './layouts/LayoutPublicPayments/LayoutPublicPayments';
import LayoutClub from './layouts/LayoutClub/LayoutClub';
import InitClubExperience from './init/InitClubExperience';
import LayoutMain from './layouts/LayoutMain/LayoutMain';
import AccessMaster from './access/AccessMaster';

import RoutesEvents from './routes/RoutesEvents';
import RoutesBookings from './routes/RoutesBookings';
import RoutesWallet from './routes/RoutesWallet';
import RoutesSettings from './routes/RoutesSettings';
import RoutesMyClub from './routes/RoutesMyClubs';
import RoutesSettingsProfile from './routes/RoutesSettingsProfile';
import RoutesSettingsPassword from './routes/RoutesSettingsPassword';
import RoutesPayments from './routes/RoutesPayments';
import { lazy } from 'react';
import LayoutCompany from './layouts/LayoutCompany/LayoutCompany';
import InitCompanyExperience from './init/InitCompanyExperience';

const PageTrainingDetail = lazy(() => import('./pages/Training/PageTrainingDetail'));
const PageClubExperienceEventDetail = lazy(() => import('./pages/Events/PageClubExperienceEventDetail'));
const PageTrainingRegister = lazy(() => import('./pages/Training/PageTrainingRegister'));
const PageEventRegister = lazy(() => import('./pages/Events/PageEventRegister'));
const PageEventPaymentStatusClubExp = lazy(() => import('./pages/PaymentStatus/PageEventPaymentStatusClubExp'));
const PageTrainingPaymentStatus = lazy(() => import('./pages/PaymentStatus/PageTrainingPaymentStatus'));
const PageClubLandingPage = lazy(() => import('./pages/ClubExperience/PageClubLandingPage'));
const PageWalletOfferPurchaseStatus = lazy(() => import('./pages/Wallet/PageWalletOfferPurchaseStatus'));
const PagePaymentStatusRecurring = lazy(() => import('./pages/PaymentStatus/PagePaymentStatusRecurring'));
const PagePaymentStatus = lazy(() => import('./pages/PaymentStatus/PagePaymentStatus'));
const PageRedirectSlugToID = lazy(() => import('./pages/ClubExperience/PageRedirectSlugToID'));
const AccessUnauthenticated = lazy(() => import('./access/AccessUnauthenticated'));
const PageRegister = lazy(() => import('./pages/Onboarding/PageRegister'));
const PageSignupVerification = lazy(() => import('./pages/Onboarding/PageSignupVerification'));
const PageEmailVerification = lazy(() => import('./pages/Onboarding/PageEmailVerification'));
const PageEventDetailLegacy = lazy(() => import('./pages/Events/PageEventDetailLegacy'));
const PageEventsRegisterLegacy = lazy(() => import('./pages/Events/PageEventsRegisterLegacy'));
const PageEventPaymentStatusLegacy = lazy(() => import('./pages/PaymentStatus/PageEventPaymentStatusLegacy'));
const PageCacheDebug = lazy(() => import('./pages/Debug/PageCacheDebug'));
const PageClubExperienceBookings = lazy(() => import('./pages/Bookings/PageClubExperienceBookings'));
const PageClubExperienceEventsListsRegistered = lazy(() => import('./pages/Events/PageClubExperienceEventListRegistered'));
const PageTrainingsListRegistered = lazy(() => import('./pages/Training/PageTrainingsListRegistered'));
const PageClubExperienceBookingsRecurrenceDetail = lazy(() => import('./pages/Bookings/PageClubExperienceRecurrenceDetail'));
const PageSettings = lazy(() => import('./pages/Settings/PageSettings'));
const PageProfile = lazy(() => import('./pages/Profile/PageProfile'));
const PageCustomersAddEdit = lazy(() => import('./pages/Profile/PageProfileEdit'));
const PageProfileChangePassword = lazy(() => import('./pages/Profile/PageProfileChangePassword'));
const PageMyClubs = lazy(() => import('./pages/MyClubs/PageMyClubs'));
const PageWalletList = lazy(() => import('./pages/Wallet/PageWalletList'));
const PageWalletDetail = lazy(() => import('./pages/Wallet/PageWalletDetail'));
const PageWalletTransactionDetail = lazy(() => import('./pages/Wallet/PageWalletTransactionDetail'));
const PagePackagePurchaseStatus = lazy(() => import('./pages/Packages/PagePackagePurchaseStatus'));
const PageUserPackages = lazy(() => import('./pages/Packages/PageUserPackages'));
const PageUserPackagesDetail = lazy(() => import('./pages/Packages/PageUserPackagesDetail'));
const PageCompetitionEventRegister = lazy(() => import('./pages/Events/PageCompetitionEventRegister'));
const PageWalletTopupPurchaseStatus =  lazy(()=>import('./pages/Wallet/PageWalletTopupPurchaseStatus'));
const RoutesOpenMatches = lazy(()=>import('./routes/RoutesOpenMatches'));
const PageAllClubs = lazy(() => import('./pages/CompanyExperience/PageAllClubs'));
const PageAllClubsEvents = lazy(()=>import('./pages/CompanyExperience/PageAllClubsEvents'));
const PageAllClubsTraining = lazy(()=>import('./pages/CompanyExperience/PageAllClubsTraining'));
const PageAllClubsOffers = lazy(()=>import('./pages/CompanyExperience/PageAllClubsOffers'));
const PageActivity = lazy(()=>import('./pages/Activity/PageActivity'));
const PageTransactions = lazy(()=>import('./pages/Transactions/PageTransactions'));

function App() {

  return (
      <Routes>
        <Route element={<AccessMaster/>}>

          {/*Debugging and Testing Routes*/}
          <Route path={'/debug/cache'} element={<PageCacheDebug/>}/>

          {/*Public Player Login and Password reset routes*/}
          <Route element={<AccessUnauthenticated/>}>
            <Route path="/login"
                   element={<PageLogin/>}
            />
            <Route path="/signup/email/verifylink"
                   element={<PageEmailVerification/>}
            />
            <Route path="/signup/email-verification/:token"
                   element={<PageSignupVerification/>}
            />
          </Route>

          <Route path="/forgot-password"
                 element={<PageForgotPassword/>}
          />
          <Route path="/reset-password/:token"
                 element={<PageResetPassword/>}
          />

          <Route path="/signup"
                 element={<PageRegister/>}
          />

          {/*Public Booking Payment Routes*/}
          <Route element={<LayoutMain shouldRedirectOnLogin={false}/>}>

            {/*Paths reached from booking email */}
            <Route path={'/payments/confirm/:token'}
                   element={<PagePaymentStatus/>}
            />
            <Route path={'/public/payments/recurring-booking/status/:token'}
                   element={<PagePaymentStatusRecurring/>}
            />

            {/*Paths reached from logged out player side booking creation*/}
            <Route path={'/payments/status/:token'}
                   element={<PagePaymentStatus/>}
            />
            <Route path={'/public/payments/recurring-booking/status'}
                   element={<PagePaymentStatusRecurring/>}
            />
          </Route>

          {/*Public Event Payment Routes*/}
          <Route element={<LayoutPublicPayments/>}>
            <Route path={'/event-payments/status/:token'}
                   element={<PageEventPaymentStatusLegacy/>}/>
          </Route>


          {/*Public Player Routes*/}
          <Route element={<LayoutMain/>}>
            {/*<Route path={'/public'}*/}
            {/*       element={<PageSearchPublic/>}*/}
            {/*/>*/}
            {/*<Route path={'/public/search-results'}*/}
            {/*       element={<PageSearchResultsPublic/>}*/}
            {/*/>*/}
            {/*<Route path={`/public/schedule/*`}*/}
            {/*       element={<PageSchedulePublic/>}*/}
            {/*/>*/}
            <Route path={`/public/event-detail/*`}
                   element={<PageEventDetailLegacy/>}
            />
            <Route path={`/public/register`}
                   element={<PageEventsRegisterLegacy/>}
            />
          </Route>

          {/*Club Experience Redirect Slug Route*/}
          <Route path="/clubs">
            <Route path=":slug" element={<PageRedirectSlugToID/>}/>
          </Route>
          {/*Company Experience  */}
          <Route element={<InitCompanyExperience/>}>
          <Route element={<LayoutCompany/>}>
              <Route path={'/company'}>
              <Route path={':companyId/*'}
                       element={<PageAllClubs/>}
                />
              <Route 
              path=':companyId/events' 
              element={<PageAllClubsEvents experience={'COMPANY'}/>}
              />
              <Route 
              path=':companyId/offers/*' 
              element={<PageAllClubsOffers experience={'COMPANY'}/>}
              />
              <Route 
              path=':companyId/openMatches/*' 
              element={<RoutesOpenMatches experience={'COMPANY'}/>}
              />
              {/* <Route
              path=':companyId/myActivity'
              element={<PageActivity experience={'COMPANY'}/>}
              /> */}
              </Route>
          </Route>
          </Route>
          {/*Club Experience Routes*/}
          <Route element={<InitClubExperience/>}>
            <Route element={<LayoutClub/>}>
              <Route path={'/club'}>
             {/* new company view pages start here */}
             

                 <Route 
                 path=':clubId/all-clubs/events' 
                 element={<PageAllClubsEvents/>}
                 />
                  <Route 
                 path=':clubId/all-clubs/trainings' 
                 element={<PageAllClubsTraining/>}
                 />
                 <Route 
                 path=':clubId/all-clubs/offers/*' 
                 element={<PageAllClubsOffers experience={'CLUB'}/>}
                 />
                <Route path={':clubId/*'}
                       element={<PageClubLandingPage/>}
                />
                <Route path=":clubId/signup/email-verification/:token"
                   element={<PageSignupVerification/>}
            />
            <Route path=":clubId/payments/recurring-booking/status"
                           element={<PagePaymentStatusRecurring/>}/>
             <Route path=":clubId/reset-password/:token"
                 element={<PageResetPassword/>}
          />
                <Route element={<AccessAuthenticated/>}>
                  <>
                    {/* bookings */}
                    <Route path={`:clubId/bookings/*`}
                           element={<PageClubExperienceBookings/>}/>

                    <Route path={':clubId/bookings/recurring/recurrence-detail'}
                           element={
                             <PageClubExperienceBookingsRecurrenceDetail/>}/>

                    <Route path=":clubId/payments/recurring-booking/status"
                           element={<PagePaymentStatusRecurring/>}/>
                    {/* events */}
                    <Route path={`:clubId/events`}
                           element={
                             <PageClubExperienceEventsListsRegistered/>}/>
                             {/* my trainings */}
                     <Route path={`:clubId/trainings`}
                           element={
                             <PageTrainingsListRegistered/>}/>
                    {/* my clubs/ */}
                    <Route path={`:clubId/my-clubs`}
                           element={<PageMyClubs/>}/>
                    {/* wallet */}
                    <Route path={`:clubId/wallet/*`}
                           element={<PageWalletList/>}/>

                    <Route path=":clubId/wallet/:id/:clubName/detail/*"
                           element={<PageWalletDetail
                               isClubExperience={true}/>}/>

                    <Route
                        path=":clubId/wallet/:id/:clubName/transaction/detail"
                        element={<PageWalletTransactionDetail
                            isClubExperience={true}/>}/>

                    <Route path=":clubId/wallet/:id/offer/payment-status"
                           element={<PageWalletOfferPurchaseStatus
                               isClubExperience={true}/>}/>

                    {/* settings */}
                    <Route path={`:clubId/settings`} element={<PageSettings/>}/>

                    <Route path={`:clubId/settings/profile`}
                           element={<PageProfile/>}/>

                    <Route path={`:clubId/settings/profile/edit`}
                           element={<PageCustomersAddEdit/>}/>

                    <Route path={`:clubId/settings/change-password/*`}
                           element={<PageProfileChangePassword/>}/>

                    <Route path={`:clubId/settings/packages`}
                           element={<PageUserPackages/>}/>
                    
                    <Route path={`:clubId/settings/transactions`}
                           element={<PageTransactions/>}/>

                    <Route path={`:clubId/settings/packages/detail`}
                           element={<PageUserPackagesDetail/>}/>
                     <Route
                     path=':clubId/myActivity'
                     element={<PageActivity experience={'CLUB'}/>}
                     />
                  </>
                  {/* <Route path={':clubId/bookings'}
                       element={<PageClubExperienceBookings/>}
                /> */}
                </Route>

                <Route path={':clubId/event-detail/*'}
                       element={<PageClubExperienceEventDetail/>}
                />

                <Route path={':clubId/register'}
                       element={<PageEventRegister/>}
                />
                
                <Route path={':clubId/competition/register'}
                       element={<PageCompetitionEventRegister/>}
                />
                <Route path={':clubId/competition/registerDetail/:token'}
                       element={<PageCompetitionEventRegister/>}
                />
                <Route path={':clubId/competition/event-payments/status/:token/:origin'}
                       element={<PageCompetitionEventRegister/>}
                />

              <Route path={':clubId/training-payments/status/:trainingId/:origin'}
                       element={<PageTrainingPaymentStatus/>}/>
                <Route path={':clubId/event-payments/status/:token'}
                       element={<PageEventPaymentStatusClubExp/>}/>
                
                <Route path={':clubId/event-payments/status/:token/:origin'}
                       element={<PageEventPaymentStatusClubExp/>}/>
              <Route path={':clubId/training-detail/*'}
                       element={<PageTrainingDetail/>}
                />
                 <Route path={':clubId/register-training'}
                       element={<PageTrainingRegister/>}
                />
                <Route path=":clubId/packages/:packageId/payment-status"
                       element={<PagePackagePurchaseStatus/>}
                />

                <Route path=":clubId/landingPage/offer/payment-status"
                       element={<PageWalletOfferPurchaseStatus
                            experience={'club-experience'} isClubExperience={true}/>}/>
                
                <Route path=":clubId/wallet/topup/payment-status"
                       element={<PageWalletTopupPurchaseStatus
                           experience={'club-experience'} isClubExperience={true}/>}/>
                <Route path=":clubId/wallet/topup/payment-status/:cId"
                       element={<PageWalletTopupPurchaseStatus
                           experience={'club-experience'} isClubExperience={true}/>}/>

                <Route path={':clubId/payments/status/:token'}
                       element={<PagePaymentStatus/>}
                />

                <Route path=":clubId/payments/status"
                       element={<PagePaymentStatus/>}/>
                 <Route 
                 path=':clubId/openMatches/*' 
                 element={<RoutesOpenMatches/>}
                 />
                 
              </Route>
            </Route>
          </Route>

          {/*Authenticated Routes*/}
          <Route element={<AccessAuthenticated/>}>
            <Route element={<LayoutMain/>}>
              {/*{RoutesSearch}*/}
              {RoutesEvents}
              {RoutesMyClub}
              {RoutesBookings}
              {RoutesWallet}
              {RoutesSettings}
              {RoutesSettingsProfile}
              {RoutesSettingsPassword}
              {RoutesPayments}
            </Route>
          </Route>
        </Route>
      </Routes>
  );

}

export default App;
