import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {clubsPolicyList_GET} from '../../vendor/redux/actions/policies';
import useUser from '../access/useUser';

function usePolicies(clubId) {

  const [policies, setPolicies] = useState();
  const dispatch = useDispatch();
  const user = useUser();
  function fetchAndSetPolicies(clubId) {

    dispatch(clubsPolicyList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('policies_GET Success', res);
      setPolicies(res.data.data);
    }

    function cbFail(e) {
      console.log('policies_GET Fail', e);
    }

  }

  useEffect(() => {
    if (clubId) {
      fetchAndSetPolicies(clubId);
    }
  }, [clubId, user]);

  return policies;

}

export default usePolicies;